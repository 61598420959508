exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-bespoke-fabrication-js": () => import("./../../../src/pages/services/bespoke-fabrication.js" /* webpackChunkName: "component---src-pages-services-bespoke-fabrication-js" */),
  "component---src-pages-services-maintenance-refurbishment-js": () => import("./../../../src/pages/services/maintenance-refurbishment.js" /* webpackChunkName: "component---src-pages-services-maintenance-refurbishment-js" */),
  "component---src-pages-services-planned-preventative-maintenance-js": () => import("./../../../src/pages/services/planned-preventative-maintenance.js" /* webpackChunkName: "component---src-pages-services-planned-preventative-maintenance-js" */),
  "component---src-pages-services-plant-upgrades-js": () => import("./../../../src/pages/services/plant-upgrades.js" /* webpackChunkName: "component---src-pages-services-plant-upgrades-js" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-belly-plate-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/belly-plate/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-belly-plate-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-belt-roller-clip-repair-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/belt-roller-clip-repair/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-belt-roller-clip-repair-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-bunker-door-repair-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/bunker-door-repair/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-bunker-door-repair-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-gearbox-upgrade-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/gearbox-upgrade/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-gearbox-upgrade-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-hard-facing-shredder-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/hard-facing-shredder/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-hard-facing-shredder-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-bail-breaker-linear-bearing-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/replacements/bail-breaker-linear-bearing/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-bail-breaker-linear-bearing-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-belt-conveyor-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/replacements/belt-conveyor/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-belt-conveyor-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-conveyor-tail-drum-bearing-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/replacements/conveyor-tail-drum-bearing/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-conveyor-tail-drum-bearing-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-disc-screen-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/replacements/disc-screen/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-disc-screen-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-drive-drum-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/replacements/drive-drum/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-drive-drum-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-loading-hopper-belt-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/replacements/loading-hopper-belt/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-loading-hopper-belt-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-trommel-drive-wheel-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/replacements/trommel-drive-wheel/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-trommel-drive-wheel-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-trommel-thrust-wheel-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/replacements/trommel-thrust-wheel/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-replacements-trommel-thrust-wheel-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-trommel-wear-plates-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/engineering-maintenance/trommel-wear-plates/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-engineering-maintenance-trommel-wear-plates-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-fabrication-belt-chute-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/fabrication/belt-chute/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-fabrication-belt-chute-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-fabrication-high-level-transfer-chute-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/fabrication/high-level-transfer-chute/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-fabrication-high-level-transfer-chute-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-fabrication-platform-chute-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/fabrication/platform-chute/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-fabrication-platform-chute-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-fabrication-walkway-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/fabrication/walkway/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-fabrication-walkway-index-mdx" */),
  "component---src-templates-project-js-content-file-path-content-projects-inspections-chain-conveyor-index-mdx": () => import("./../../../src/templates/project.js?__contentFilePath=/opt/buildhome/repo/content/projects/inspections/chain-conveyor/index.mdx" /* webpackChunkName: "component---src-templates-project-js-content-file-path-content-projects-inspections-chain-conveyor-index-mdx" */),
  "component---src-templates-project-list-js": () => import("./../../../src/templates/project-list.js" /* webpackChunkName: "component---src-templates-project-list-js" */)
}

